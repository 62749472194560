import { useStaticQuery, graphql, Link } from "gatsby";
import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Button from "react-bootstrap/Button";
import { StaticImage } from "gatsby-plugin-image";
// import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
// import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
// import gsap from "gsap";
// import { motion } from "framer-motion";
import MapGL, { NavigationControl, Marker, Popup } from "@urbica/react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import { BiCurrentLocation } from "react-icons/bi";
import InputField from "../components/inputField";
import { useGeolocated } from "react-geolocated";
import gsap from "gsap";
import slugify from "slugify";
// import { Card } from "react-bootstrap";
// import ContactUsPage from "./contact-us";
// import { MultiSelect } from "react-multi-select-component";

// import ButtonGroup from "react-bootstrap/ButtonGroup";
// import PropertyCard from "../components/property-card";
import Sticky from "react-stickynode";
import CartContext from "../context/CartContext";
import Form from "react-bootstrap/Form";
import ListingsPropertyCard from "../components/Listings-property-card";
import AnimatedImageCard from "../components/animated-image-card";
import ContactFormHome from "../components/contact-form-home";
import PreferToTalk from "../components/prefer-to-talk";

import { GrMapLocation } from "react-icons/gr";
import FilterModal from "../components/filter-modal";
import MapCardModal from "../components/map-card-modal";
import {
	MdFormatListBulleted,
	MdLocationPin,
	MdOutlineClear,
} from "react-icons/md";
import MapSimpleCard from "../components/map-simple-card";
import PropertiesFilters from "../components/properties-filters";
import MapCardModalDesktop from "../components/map-card-modal-desktop";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import ImageSlider from "../components/image-slider";
import VerticalListingsPropertyCard from "../components/vertical-listings-property-card";
import ListingsPropertyCardCarousel from "../components/Listings-property-card-carousel";
import ContactFormNarrow from "../components/contact-form-narrow";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
	require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const PropertiesPage = () => {
	const tl = useRef();
	const data = useStaticQuery(graphql`
		query {
			allAirtableProperties(filter: { isFloor: { nin: 1 } }) {
				edges {
					node {
						addressLineOne
						desksFrom
						desksTo
						subwayStation1
						subwayStation1DistanceKm
						subwayStation1DurationMins
						subwayStation2
						subwayStation2DistanceKm
						subwayStation2DurationMins
						subwayStation3
						subwayStation3DistanceKm
						subwayStation3DurationMins
						type
						provider
						rentPM
						airtableId
						locationLatitude
						locationLongitude
						name
						services
						amenities
						features
						photosNew {
							childImageSharp {
								gatsbyImageData(
									quality: 30
									placeholder: BLURRED
									formats: AUTO
								)
							}
						}
					}
				}
			}
			heroInterior: wpMediaItem(title: { eq: "hero-interior" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 30
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			paddington: wpMediaItem(title: { eq: "city-of-london" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			canaryWharf: wpMediaItem(title: { eq: "canary-wharf-2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			piccadilly: wpMediaItem(title: { eq: "westminster" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			westminster: wpMediaItem(title: { eq: "london-bridge" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			heroInterior: wpMediaItem(title: { eq: "hero-interior" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 30
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const paddington = data.paddington;
	const canaryWharf = data.canaryWharf;
	const piccadilly = data.piccadilly;
	const westminster = data.westminster;
	function round5(x) {
		return Math.ceil(x / 5) * 5;
	}
	const {
		cart,
		settingVal,
		selectedOfficeType,
		selectedFeatures,
		viewport,
		onLocationChange,
		numberOfPeople,
		budgetPerDesk,
		options,
		filteredOffices,
		newServiceOptions,
		newAmenitiesOptions,
		buttonChecked,
		newOptions,
		getDistanceFromLatLonInKm,
		distancePoint,
		OnRemoveFromCart,
		OnAddToCart,
		onLocationChangeScroll,
		distancePanPoint,
		onLocationChangePan,
		shortOffices,
		sortedBy,
		distanceAmount,
		idLocation,
		capacityMinMax,
		budgetMinMax,
	} = React.useContext(CartContext);

	// console.log(filteredOffices);

	const [checked, setChecked] = useState(false);

	const siteUrl = data.site.siteMetadata.siteUrl;
	// const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

	const [activePopup, setActivePopup] = useState(-1);
	const [showPopup, setShowPopup] = useState(false);
	const [allowLocation, setAllowLocation] = useState(false);
	const [tempFeatures, setTempFeatures] = useState(selectedFeatures);
	const [officeByDistance, setOfficeByDistance] = useState([]);
	const [officeByPrice, setOfficeByPrice] = useState([]);
	const [officeByPriceHigh, setOfficeByPriceHigh] = useState([]);
	const [officeAmount, setOfficeAmount] = useState(5);
	const [mapChecked, setMapChecked] = useState(true);
	const [mapMobileChecked, setMapMobileChecked] = useState(false);
	const [urlLocation, setUrlLocation] = useState("");
	var queryParameters = "";
	var uniqueLocation = "";

	var floors = [];

	useEffect(() => {
		if (typeof Window !== "undefined") {
			queryParameters = new URLSearchParams(window.location.search);
			uniqueLocation = queryParameters.get("location") || "";
			setUrlLocation(uniqueLocation);
			// console.log(uniqueLocation);
		}
	}, [idLocation]);

	useEffect(() => {
		if (typeof Window !== "undefined") {
			if (urlLocation === "Bank") {
				onLocationChange(51.5134724, -0.090159);
				settingVal("Bank, London", "locationValue");
			} else if (urlLocation === "Canary Wharf and Docklands") {
				onLocationChange(51.506156, -0.021452);
				settingVal("Canary Wharf and Docklands, London", "locationValue");
			} else if (urlLocation === "Holborn") {
				onLocationChange(51.517431, -0.1204831);
				settingVal("Holborn, London", "locationValue");
			} else if (urlLocation === "Clerkenwell") {
				onLocationChange(51.523727, -0.105556);
				settingVal("Clerkenwell, London", "locationValue");
			} else if (urlLocation === "Covent Garden") {
				onLocationChange(51.511736, -0.122677);
				settingVal("Covent Garden, London", "locationValue");
			} else if (urlLocation === "Kings Cross") {
				onLocationChange(51.529108, -0.122413);
				settingVal("Kings Cross, London", "locationValue");
			} else if (urlLocation === "Knightsbridge") {
				onLocationChange(51.500844, -0.166965);
				settingVal("Knightsbridge, London", "locationValue");
			} else if (
				urlLocation === "London Bridge / Borough" ||
				urlLocation === "London Bridge"
			) {
				onLocationChange(51.5079111, -0.0903026);
				settingVal("London Bridge / Borough, London", "locationValue");
			} else if (urlLocation === "Marylebone") {
				onLocationChange(51.522059, -0.150455);
				settingVal("Marylebone, London", "locationValue");
			} else if (urlLocation === "Mayfair") {
				onLocationChange(51.511087, -0.147058);
				settingVal("Mayfair, London", "locationValue");
			} else if (urlLocation === "Midtown") {
				onLocationChange(51.507322, -0.127647);
				settingVal("Midtown, London", "locationValue");
			} else if (urlLocation === "Paddington") {
				onLocationChange(51.516305, -0.179242);
				settingVal("Paddington, London", "locationValue");
			} else if (urlLocation === "Shoreditch") {
				onLocationChange(51.526669, -0.079893);
				settingVal("Shoreditch, London", "locationValue");
			} else if (urlLocation === "Soho") {
				onLocationChange(51.513163, -0.131175);
				settingVal("Soho, London", "locationValue");
			} else if (urlLocation === "St Pauls") {
				onLocationChange(51.513962, -0.10114);
				settingVal("St Pauls, London", "locationValue");
			} else if (urlLocation === "Victoria") {
				onLocationChange(51.495979, -0.141317);
				settingVal("Victoria, London", "locationValue");
			} else if (urlLocation === "Piccadilly Circus") {
				settingVal("Piccadilly Circus, London", "locationValue");
				onLocationChange(51.5099786, -0.1353476);
			} else if (urlLocation === "Westminster") {
				settingVal("Westminster, London", "locationValue");
				onLocationChange(51.4975158, -0.137249);
			} else if (urlLocation === "London") {
				settingVal("London", "locationValue");
				onLocationChange(51.5136461, -0.1814201);
			}
		}
	}, [urlLocation]);

	const { coords, isGeolocationAvailable, isGeolocationEnabled } =
		useGeolocated({
			positionOptions: {
				enableHighAccuracy: true,
			},
			isOptimisticGeolocationEnabled: false,
			userDecisionTimeout: 5000,
		});

	// const onPinClick = (pin, active) => {
	// 	// console.log("pin", pin, "activePopup", activePopup);

	// 	setActivePopup(pin);

	// 	setShowPopup(true);
	// };

	const OnLocationChangeGeo = () => {
		if (isGeolocationAvailable && isGeolocationEnabled && coords) {
			onLocationChange(coords.latitude, coords.longitude);
			settingVal("Your location", "locationValue");
		} else {
			alert("Please allow access to your location on your browser");
		}
	};

	const OnFeatureClick = (f) => {
		var featuresTemp = tempFeatures.slice();

		if (featuresTemp.includes(f)) {
			const index = featuresTemp.indexOf(f);
			if (index > -1) {
				featuresTemp.splice(index, 1);
			}
		} else if (!featuresTemp.includes(f)) {
			featuresTemp.push(f);
		}
		setTempFeatures(featuresTemp);
	};

	const OnMapToggleClick = () => {
		if (mapChecked) {
			var temp = !mapChecked;
			setOfficeAmount(officeAmount + 5);
			setMapChecked(temp);
		}
		if (!mapChecked) {
			var temp = !mapChecked;
			setMapChecked(temp);
		}
	};
	const OnMapMobileToggleClick = () => {
		if (mapMobileChecked) {
			var temp = !mapMobileChecked;

			setMapMobileChecked(temp);
		}
		if (!mapMobileChecked) {
			var temp = !mapMobileChecked;
			setMapMobileChecked(temp);
		}
	};

	const OnApplyFeatures = () => {
		// console.log(tempFeatures);
		settingVal(tempFeatures, "selectedFeatures");
	};

	const OnClearFeatures = () => {
		setTempFeatures([]);
		settingVal([], "selectedFeatures");
	};

	const handleMapCheckChange = (e) => {
		// console.log(e.target.checked);
		// if (e.target.)
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Properties",
				item: {
					url: `${siteUrl}/properties`,
					id: `${siteUrl}/properties`,
				},
			},
		],
	};

	const setCapacityHelper = (numPeople) => {
		return {
			min: numPeople?.min > 0 ? numPeople?.min : capacityMinMax?.min,
			max: numPeople?.max > 0 ? numPeople?.max : capacityMinMax?.max,
		};
	};

	const onMouseExit = () => {
		gsap.to(".map-popup-div", { opacity: 0, duration: 0.3 });
		setTimeout(popupFalse, 400);
	};

	const popupFalse = () => {
		setShowPopup(false);
	};

	const [capacity, setCapacity] = useState(setCapacityHelper(numberOfPeople));

	const setBudgetHelper = (budget) => {
		return {
			min: budget?.min > 0 ? budget?.min : budgetMinMax?.min,
			max: budget?.max > 0 ? budget?.max : budgetMinMax?.max,
			perDesk: budget?.perDesk,
		};
	};

	const [budget, setBudget] = useState(setBudgetHelper(budgetPerDesk));
	// const [budget, setBudget] = useState(
	//   budgetPerDesk > 0 ? budgetPerDesk : "Budget"
	// );
	// console.log("budget", budget);

	const [radius, setRadius] = useState(distanceAmount);

	const RADIUS_ARR = [0.25, 0.5, 1, 3, 5];

	const mapRef = useRef();

	useEffect(() => {
		const newVewport = {
			...viewport,
			latitude: viewport.latitude,
			longitude: viewport.longitude,
		};

		// onLocationChange(viewport.latitude, viewport.longitude);
		// console.log(selectedFeatures);
	}, []);

	useEffect(() => {
		const officesTemp = filteredOffices
			.sort((p1, p2) =>
				getDistanceFromLatLonInKm(
					distancePoint.latitude,
					distancePoint.longitude,
					p1.node.locationLatitude,
					p1.node.locationLongitude
				) *
					0.621371 >
				getDistanceFromLatLonInKm(
					distancePoint.latitude,
					distancePoint.longitude,
					p2.node.locationLatitude,
					p2.node.locationLongitude
				) *
					0.621371
					? 1
					: getDistanceFromLatLonInKm(
							distancePoint.latitude,
							distancePoint.longitude,
							p1.node.locationLatitude,
							p1.node.locationLongitude
					  ) *
							0.621371 <
					  getDistanceFromLatLonInKm(
							distancePoint.latitude,
							distancePoint.longitude,
							p2.node.locationLatitude,
							p2.node.locationLongitude
					  ) *
							0.621371
					? -1
					: 0
			)
			.slice();
		// console.log(filteredOffices);
		const officesTemp2 = filteredOffices
			.slice()
			.sort((a, b) => parseFloat(a.node.rentPM) - parseFloat(b.node.rentPM));
		const officesTemp3 = filteredOffices
			.slice()
			.sort((a, b) => parseFloat(b.node.rentPM) - parseFloat(a.node.rentPM));
		setOfficeByDistance(officesTemp);

		setOfficeByPrice(officesTemp2);

		setOfficeByPriceHigh(officesTemp3);
	}, [filteredOffices]);

	const [modalShow, setModalShow] = React.useState(false);
	const [modalShowDesktop, setModalShowDesktop] = React.useState(false);
	const [modalProperty, setModalProperty] = React.useState("");

	useEffect(() => {
		if (modalProperty?.node?.floorsAvailable !== null) {
			floors =
				modalProperty?.node?.floorsAvailable
					?.sort((a, b) => parseFloat(a.rentPM) - parseFloat(b.rentPM))
					?.slice() || [];
		}
	}, [modalProperty]);

	useEffect(() => {
		const officesTemp = officeByDistance.slice(0, officeAmount);

		const officesTemp2 = officeByPrice.slice(0, officeAmount);

		const officesTemp3 = officeByPriceHigh.slice(0, officeAmount);

		if (sortedBy === "Distance" || sortedBy === "Sort") {
			settingVal(officesTemp, "shortOffices");
		} else if (sortedBy === "Lowest Price") {
			settingVal(officesTemp2, "shortOffices");
		} else if (sortedBy === "Highest Price") {
			settingVal(officesTemp3, "shortOffices");
		}
	}, [officeByDistance, officeAmount, sortedBy]);

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Properties | Your Desk Workplace Solutions"
				description="Find your perfect office solution with the UK's most trusted brokerage."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/properties`,
					title: "Properties | Your Desk Workplace Solutions",
					description:
						"Find your perfect office solution with the UK's most trusted brokerage.",
					images: [
						{
							url: `${data.heroInterior?.localFile.publicURL}`,
							width: `${data.heroInterior?.localFile.childImageSharp.original.width}`,
							height: `${data.heroInterior?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroInterior?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					{/* <BgImage

						Tag="section"
						className="hero"
						id="hero-home"
						image={pluginImage}
					>
						<Container className="h-100">
							<Row className="align-items-center h-100">
								<Col xs={12}>
									<h1
										className="text-uppercase text-white"
										id="home-hero-headline"
									>
										Lorem Ipsum
									</h1>
									<p className="text-white" id="home-hero-subheading">
										Lorem ipsum dolor sit amet, consectetur adipiscing elit.
										Nunc mollis nulla justo, quis venenatis enim tempor vitae.
										In hac habitasse platea dictumst. Nam lectus risus,
										convallis vel dictum eu, interdum a mi.
									</p>
								</Col>
							</Row>
						</Container>
					</BgImage> */}

					<section className=" bg-primary pb-1 pt-1">
						<Container>
							<Row className="justify-content-center">
								<Col lg={8}>
									<Row className="align-items-center py-3 ">
										<Col xs={10} sm={11} lg={9}>
											<div className=" d-flex align-items-center">
												{/* <FaSearchLocation className="fs-2 opacity-25" /> */}
												<InputField propertiesPage />
											</div>
										</Col>
										<Col className=" pb-2 d-xl-none" xs={2} sm={1}>
											{" "}
											<a
												className="align-self-center"
												style={{ cursor: "pointer" }}
												onClick={() => OnLocationChangeGeo()}
											>
												<BiCurrentLocation className="fs-3 text-white" />
											</a>
										</Col>
										<Col className=" pt-4 pt-xl-0" lg={3} xs={12}>
											<Button
												as={Link}
												to="/properties"
												variant="outline-white w-100 pt-2 "
												style={{ height: "46px", borderRadius: "4px" }}
											>
												Refine your search
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</section>

					<section className=" pt-4  ">
						<Container>
							<Row className="d-block d-xl-none  gx-3 gx-sm-0">
								<Col>
									<Row className="px-3 mw-100 justify-content-center">
										{/* <button className="btn btn-white border border-dark-grey text-dark-grey py-2 px-6">

                      <BsSliders className="fs-2" />
                    </button> */}
										<Col className="text-center mb-4" xs={6}>
											<a
												style={{
													cursor: "pointer",
													border: "1px solid black",
													borderRadius: "4px",
												}}
												onClick={() => OnMapMobileToggleClick()}
												className=" text-dark-grey p-2   text-center  d-inline-block"
											>
												{mapMobileChecked === false && (
													<GrMapLocation className="fs-2 text-center" />
												)}
												{mapMobileChecked === true && (
													<MdFormatListBulleted className="fs-2" />
												)}
											</a>
										</Col>
									</Row>
								</Col>
							</Row>

							{mapMobileChecked === false && (
								<Row
									id="content"
									className="h-100 d-none d-xl-flex justify-content-center position-relative "
								>
									<Col className="psoition-relative">
										{/* <Filters /> */}
										<Row
											className="position-relative justify-content-center mt-0 py-0 mb-0"
											style={{ index: 1 }}
										>
											{shortOffices?.map((property, i, a) => (
												<Col xs={8} className="mb-5">
													{" "}
													<ListingsPropertyCard property={property} index={i} />
												</Col>
											))}
											{shortOffices?.length < 1 && (
												<Col>
													<h2 className="fs-3 mt-4">No matches</h2>
													<p>
														Try changing some of your filters or adjusting your
														search radius.
													</p>
												</Col>
											)}
										</Row>
										<div
											style={{ zIndex: 4 }}
											className="position-absolute d-none d-xl-inline-block top-0 end-0 p-5"
										>
											<a
												style={{
													cursor: "pointer",
													border: "1px solid black",
													borderRadius: "4px",
												}}
												onClick={() => OnMapMobileToggleClick()}
												className=" text-dark-grey p-2 bg-white  text-center  d-inline-block"
											>
												{mapMobileChecked === false && (
													<GrMapLocation className="fs-2 text-center" />
												)}
												{mapMobileChecked === true && (
													<MdFormatListBulleted className="fs-2" />
												)}
											</a>
										</div>
									</Col>
								</Row>
							)}
							{mapMobileChecked === false && (
								<Row id="content" className="h-100 d-xl-none mt-4">
									<Col>
										{/* <Filters /> */}
										<Row
											className="position-relative mt-0 py-0 mb-0"
											style={{ index: 1 }}
										>
											{shortOffices?.map((property, i, a) => (
												<Col className="mb-5" xs={12} lg={6}>
													{" "}
													<VerticalListingsPropertyCard
														property={property}
														index={i}
													/>
												</Col>
											))}
											{shortOffices?.length < 1 && (
												<Col>
													<h2 className="fs-3 mt-4">No matches</h2>
													<p>
														Try changing some of your filters or adjusting your
														search radius.
													</p>
												</Col>
											)}
										</Row>
									</Col>
								</Row>
							)}
							{mapMobileChecked === false &&
								shortOffices?.length > 0 &&
								filteredOffices?.length > shortOffices?.length && (
									<Row>
										<Col
											lg={mapChecked === true ? 6 : 12}
											className="text-center"
										>
											<Button
												style={{ borderRadius: "200px" }}
												onClick={() => setOfficeAmount(officeAmount + 5)}
												variant="dark-blue w-100 w-md-auto px-4"
											>
												LOAD MORE
											</Button>
										</Col>
									</Row>
								)}
						</Container>
					</section>
					<section>
						<Container>
							<Row>
								<Col>
									{" "}
									{mapMobileChecked === true && (
										<>
											{" "}
											{modalShow && (
												<MapCardModal
													show={modalShow}
													property={modalProperty}
													onHide={() => setModalShow(false)}
												/>
											)}
											<div className="  position-relative">
												<div
													style={{ zIndex: 4 }}
													className="position-absolute d-none d-xl-inline-block top-0 end-0 p-5"
												>
													<a
														style={{
															cursor: "pointer",
															border: "1px solid black",
															borderRadius: "4px",
														}}
														onClick={() => OnMapMobileToggleClick()}
														className=" text-dark-grey p-2 bg-white  text-center  d-inline-block"
													>
														{mapMobileChecked === false && (
															<GrMapLocation className="fs-2 text-center" />
														)}
														{mapMobileChecked === true && (
															<MdFormatListBulleted className="fs-2" />
														)}
													</a>
												</div>
												<div className="d-xl-none">
													<MapGL
														ref={mapRef}
														style={{ width: "100%", height: "70vh" }}
														mapStyle="mapbox://styles/rjmdigital/clgnfpzan00dl01pcebkc629e"
														accessToken="pk.eyJ1IjoicmptZGlnaXRhbCIsImEiOiJjbGRibGk5d2MwMXJrM3ZsajRpMm93dXl1In0.OrnQu39yRgj7shF3hYX3xw"
														bounds={viewport?.bounds}
														latitude={viewport?.latitude}
														longitude={viewport?.longitude}
														zoom={viewport?.zoom}
														onViewportChange={(e) => onLocationChangePan(e)}
														viewportChangeMethod="flyTo"
													>
														{filteredOffices?.map((property, i) => {
															return (
																<Marker
																	key={i}
																	longitude={property.node.locationLongitude}
																	latitude={property.node.locationLatitude}
																	onClick={() => {
																		setModalProperty(property);
																		setModalShow(true);
																	}}
																	// onClick={() => onPinClick(property)}
																>
																	{(viewport?.latitude !==
																		property.node.locationLatitude ||
																		viewport?.longitude !==
																			property.node.locationLongitude) && (
																		<MdLocationPin
																			className=" fs-2 text-primary"
																			style={{
																				maxWidth: "28px",
																				cursor: "pointer",
																			}}
																		/>
																	)}
																	{viewport?.latitude ===
																		property.node.locationLatitude &&
																		viewport?.longitude ===
																			property.node.locationLongitude && (
																			<MdLocationPin
																				className="text-secondary fs-2"
																				style={{
																					maxWidth: "28px",
																					cursor: "pointer",
																				}}
																			/>
																		)}
																</Marker>
															);
														})}
													</MapGL>
												</div>
												<div className=" d-none d-xl-block">
													<MapGL
														ref={mapRef}
														style={{ width: "100%", height: "100vh" }}
														mapStyle="mapbox://styles/rjmdigital/clgnfpzan00dl01pcebkc629e"
														accessToken="pk.eyJ1IjoicmptZGlnaXRhbCIsImEiOiJjbGRibGk5d2MwMXJrM3ZsajRpMm93dXl1In0.OrnQu39yRgj7shF3hYX3xw"
														bounds={viewport?.bounds}
														latitude={viewport?.latitude}
														longitude={viewport?.longitude}
														zoom={viewport?.zoom}
														onViewportChange={(e) => onLocationChangePan(e)}
														viewportChangeMethod="flyTo"
													>
														{filteredOffices?.map((property, i) => {
															return (
																<Marker
																	key={i}
																	longitude={property.node.locationLongitude}
																	latitude={property.node.locationLatitude}
																	onClick={() => {
																		setModalProperty(property);
																		setModalShow(true);
																	}}
																	// onClick={() => onPinClick(property)}
																>
																	{(viewport?.latitude !==
																		property.node.locationLatitude ||
																		viewport?.longitude !==
																			property.node.locationLongitude) && (
																		<MdLocationPin
																			className=" fs-2 text-primary"
																			style={{
																				maxWidth: "28px",
																				cursor: "pointer",
																			}}
																		/>
																	)}
																	{viewport?.latitude ===
																		property.node.locationLatitude &&
																		viewport?.longitude ===
																			property.node.locationLongitude && (
																			<MdLocationPin
																				className="text-secondary fs-2"
																				style={{
																					maxWidth: "28px",
																					cursor: "pointer",
																				}}
																			/>
																		)}
																</Marker>
															);
														})}
													</MapGL>
												</div>
											</div>
										</>
									)}
								</Col>
							</Row>
						</Container>
					</section>
					<section className=" position-relative mt-4" style={{ zIndex: 1 }}>
						<Container className="">
							<Row className="g-5 justify-content-between">
								<Col lg={5}>
									<Row>
										<Col>
											<h2 className="fs-1 pb-4 text-primary text-start">
												Get in touch
											</h2>
										</Col>
										<Row>
											<Col>
												<p className="mb-5 text-primary text-start">
													Fill in your contact details below and we'll put you
													in touch with one of our experts.
												</p>
											</Col>
										</Row>
									</Row>
									<ContactFormNarrow />
								</Col>
								<Col lg={7} className="ps-lg-5">
									<Row className="  pb-3 pb-lg-5">
										<Col className="text-center">
											<h2 className="fs-1 text-primary pb-4 text-start">
												Popular Locations
											</h2>
										</Col>
									</Row>
									<Row className="g-3 pb-5">
										<Col>
											<AnimatedImageCard
												maxHeight={"150px"}
												id="london"
												imageData={paddington}
											/>

											<AnimatedImageCard
												maxHeight={"150px"}
												id="canary"
												imageData={canaryWharf}
											/>

											<AnimatedImageCard
												maxHeight={"150px"}
												id="west"
												imageData={piccadilly}
											/>

											<AnimatedImageCard
												maxHeight={"150px"}
												id="bridge"
												imageData={westminster}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default PropertiesPage;
